<template>
  <b-row>
    <b-col md="4">
      <input-text
        ref="nome"
        v-model="nome"
        label="Digite Nome ou CPF"
        placeholder="Digite Nome ou CPF"
        @enter="buscar"
      />
    </b-col>
    <b-col md="auto">
      <b-button
        :disabled="btnBuscar"
        variant="primary"
        class="botao-acao-filtro mb-3 w-100"
        @click="buscar"
      >
        {{ $t('GERAL.BUSCAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button
        variant="secondary"
        class="botao-acao-filtro mb-3 w-100"
        @click="limpar"
      >
        {{ $t('GERAL.LIMPAR_PESQUISA') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import InputText from '@/components/inputs/InputText.vue';

export default {
  props: {},
  components: {
    InputText,
  },
  mounted() {},
  data() {
    return {
      nome: '',
      btnBuscar: true,
    };
  },
  watch: {
    nome: 'habilitarBotaoDeBusca',
  },
  methods: {
    buscar() {
      this.$emit('buscar', this.nome);
    },
    limpar() {
      this.nome = '';
      this.$emit('limpar');
    },
    // FUNÇÕES AUXILIARES
    habilitarBotaoDeBusca() {
      const checagemCpf = Number(this.nome);
      this.btnBuscar = this.nome.length >= 3 || checagemCpf ? false : true;
    },
  },
};
</script>
