<template>
  <b-table
    :items="pessoas"
    :fields="campos"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <div class="flex">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="editar(item.item.id)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="excluir(item.item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>
<script>
import helpers from '@/common/utils/helpers';

export default {
  props: {
    pessoas: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'codigo',
          label: this.$t('PESSOAS.CODIGO_PESSOA'),
          sortable: true,
        },
        {
          key: 'nome',
          label: this.$t('PESSOAS.NOME'),
          sortable: true,
        },
        {
          key: 'cpf',
          label: this.$t('PESSOAS.CPF'),
          sortable: true,
        },
        {
          key: 'identidade',
          label: this.$t('PESSOAS.RG'),
          sortable: true,
        },
        {
          key: 'orgaoExpedidor',
          label: this.$t('PESSOAS.ORGAO_EXPEDIDOR'),
          sortable: true,
        },
        {
          key: 'dataNascimento',
          label: this.$t('PESSOAS.DATA_NASCIMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>
