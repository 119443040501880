<template>
  <div>
    <titulo-pessoas />
    
    <filtro @limpar="listar" @buscar="realizarBusca" />

    <tabela :pessoas="items" @editar="abrirFormulario" @excluir="excluir" />

    <paginacao
      v-if="!paginacaoFiltroAtivo"
      :total="total"
      v-model="paginacao"
      @input="listar"
    />
    <paginacao
      v-else
      :total="totalFiltro"
      v-model="paginacaoFiltro"
      @input="listarPessoasFiltradas"
    />
  </div>
</template>

<script>
// Utils:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import Permisoes from '@/components/mixins/permissoes';

// Services:
import PessoaService from '@/common/services/pessoa/pessoa.service';

// Components:
import Filtro from './components/Filtro';
import Tabela from './components/Tabela';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloPessoas from '@/views/rh/pessoas/components/TituloPessoas.vue';

export default {
  name: 'Index',
  mixins: [Permisoes],
  components: {
    TituloPessoas,
    Filtro,
    Tabela,
    Paginacao,
  },
  data() {
    return {
      nome: '',
      items: [],
      total: 0,
      totalFiltro: 0,
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
      paginacaoFiltro: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
      mostrarModalAdicionarPerfis: false,
      item: {},
      botaoHabilitar: true,
      paginacaoFiltroAtivo: false,
    };
  },
  mounted() {
    this.listar();
  },
  methods: {
    realizarBusca(nome) {
      nome ? (this.nome = nome) : '';
      this.listarPessoasFiltradas();
    },
    listarPessoasFiltradas() {
      this.$store.dispatch(START_LOADING);
      PessoaService.listarPessoasFiltradas(this.paginacaoFiltro, this.nome)
        .then((result) => {
          this.items = result.data.itens;
          this.totalFiltro = result.data.paginacao.totalDeElementos;
          this.paginacaoFiltroAtivo = true;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
      return;
    },
    listar() {
      this.$store.dispatch(START_LOADING);
      PessoaService.listar(this.paginacao)
        .then((result) => {
          this.items = result.data.itens;
          this.total = result.data.paginacao.totalDeElementos;
          this.paginacaoFiltroAtivo = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirFormulario(id) {
      id
        ? this.$router.push({ name: 'editar-pessoa', params: { id } })
        : this.$router.push({ name: 'nova-pessoa' });
    },
    excluir(item) {
      const selecionarMsg =
        item.usuario !== null
          ? this.$t('USUARIOS.CONFIRMAR_EXCLUIR_COM_USUARIO')
          : this.$t('USUARIOS.CONFIRMAR_EXCLUIR');

      mensagem
        .confirmacao(null, selecionarMsg.formatUnicorn({ nome: item.nome }))
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            PessoaService.excluir(item.id)
              .then(() => {
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('USUARIOS.MENSAGEM_EXCLUIDO')
                );
                this.listar();
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
    // FUNÇÕES MODAL:
    abrirModalAdicionarPerfis(item) {
      this.item = item;
      this.mostrarModalAdicionarPerfis = true;
    },
    fecharModalAdicionarPermissoes() {
      this.item = null;
      this.mostrarModalAdicionarPerfis = false;
    },
  },
};
</script>
